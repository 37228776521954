.calendar-root {
    --grid-gap:2px;
    --text-color-light: rgba(0, 0, 0, 0.4);;
    --text-color-primary: rgba(0, 0, 0, 0.7);;
    --grid-background-color: rgb(242, 242, 242);
    --grid-foreground-color: white;
    --space-sm: 4px;
    --space-md: 8px;
    --space-lg: 16px;
    overflow: hidden;
    width: 100%;
    padding: 15px;
    @media (max-width: 600px) {
      padding: 30px 0 0 0;
  }
  }
.calendar-root-autocomplete {
  --grid-gap: 2px;
  --grid-height: 800px;
  --text-color-light: rgba(0, 0, 0, 0.4);;
  --text-color-primary: rgba(0, 0, 0, 0.7);;
  --grid-background-color: rgb(242, 242, 242);
  --grid-foreground-color: white;
  --space-sm: 4px;
  --space-md: 8px;
  --space-lg: 16px;
  width: 100%;
  padding: 0 20px 0 20px;
  height: 85vh;
  @media (max-width: 600px) {
    height: 34rem;
    padding: 0;
}
}

.panel-autocomplete {
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
  background-color: #FFF;
  padding: 40px;
  margin-bottom: 20px;
  @media (max-width: 600px) {
      minWidth: 100%;
  }
}
  
  /* | Sun | Mon | Tue | Wed | Thu | Fri | Sat  */
  .day-of-week-header-cell {
    color: var(--text-color-light);
    background-color: var(--grid-foreground-color);
    padding: 8px;
    min-width: 0;
    width:100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 2px;
  }
  
  .days-of-week,
  .days-grid {
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: var(--grid-gap);
    grid-row-gap: var(--grid-gap);
  }

  .days-grid-mobile {
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-rows: 50px 50px 50px 50px 50px 50px 50px 50px 50px 50px;
    grid-template-columns: repeat(7, 1fr);
    border: var(--grid-gap) solid var(--grid-background-color);
    grid-column-gap: var(--grid-gap);
    grid-row-gap: 16px;
  }

  .days-grid-mobile-auto {
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-rows: 50px 50px 50px 50px 50px 50px 50px 50px 50px 50px;
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: var(--grid-gap);
    grid-row-gap: var(--grid-gap);
  }
  
  .days-grid {
    position: relative;
    border: var(--grid-gap) solid var(--grid-background-color);
    background-color: var(--grid-background-color);
  }
  
  .day-grid-item-container {
    height: 11.6vh;
    position: relative;
    background-color: var(--grid-foreground-color);
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    @media (max-width: 600px) {
      height: 4rem;      
  }
  }

  .day-grid-item-header-today{
    width: 24px;
    color:#EE9051;
    opacity: 0.8;
    border-radius: 50%;
    display: inline-block;
    position:relative;
    left:0.4rem;
    top:0.4rem;
    text-align: center;
    margin-bottom: 0.5rem;
  }
  .day-grid-item-container:not(.current-month) .day-grid-item-header {
    color: var(--text-color-light);
  }
  
  /* Position the day label within the day cell */
  .day-grid-item-container .day-grid-item-header {
    width: 24px;
    color: var(--text-color-primary);
    padding: var(--space-md);
    padding-bottom: 0;
    flex-shrink: 0;
    font-weight: bold;
  }

  .day-grid-item-header-2 {
    color: var(--text-color-primary);
    padding: var(--space-md);
    padding-bottom: 0;
    flex-shrink: 0;
    font-weight: bold;
    margin-bottom: 0.3rem;
  }
  
  .day-grid-item-container > .day-content-wrapper {
    flex: 1;
    min-height: 0;
    position: relative;
  }
  
  .navigation-header {
    display: flex;
  }
 

  .month-select {
    font-size: 1rem;
    border: none;
    background-color: rgb(242, 242, 242);
    margin-bottom: 1rem;
  }

  .month-select {
    font-size: 1.2rem;
    border: none;
    background-color: rgb(242, 242, 242);
    margin-bottom: 1rem;
    color:#484848;
    cursor: pointer;
  }

  .month-select:focus {
    outline: none;
  }

  .year-select {
    font-size: 1.2rem;
    border: none;
    background-color: rgb(242, 242, 242);
    margin-bottom: 1rem;
    color:#484848;
    cursor: pointer;
    
  }

  .year-select:focus {
    outline: none;
  }
  
  .navigation-header > * {
    margin-right: var(--space-md);
  }
  